
import { defineComponent } from "vue";
import KycVerificationDetail from "@/components/page/kyc/kyc-verification/Detail.vue";

export default defineComponent({
  name: "kyc-verification-legal-entity-detail",
  components: {
    KycVerificationDetail,
  },
  setup() {
    return {};
  },
});
